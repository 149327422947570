<template>

  <section id="dashboard-ecommerce">
        <Keypress key-event="keyup" @success="key_press" />
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics :data="stats" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
         <b-col lg="12">
          <b-card>
            <b-list-group >
    <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="device in devices"
            :key="device.id"
            v-if="device.type == 'DOOR'">
      <span style="font-weight:bold;font-size:1.0rem;">{{ device.name }} <span style="font-size:0.8rem;color:red;">{{ last[device.id] }} </span></span>
      <b-button variant="danger" size="sm"  @click="open_door(device.id)">OPEN</b-button>
    </b-list-group-item>    
  </b-list-group>
          </b-card>
         </b-col>
          <!-- <b-col
            lg="6"
            md="3"
            cols="6"
            v-for="device in devices"
            :key="device.id"
            v-if="device.type == 'DOOR'"
          >
          <b-card
            body-class="pb-50"
          >
            <h4 class="font-weight-bolder mb-1">
              {{device.name}}
            </h4>
            
            <b-button variant="danger" block class="mb-2" @click="open_door(device.id)">OPEN</b-button>
          </b-card>
          </b-col> -->
          <!--/ Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
          </b-col>

        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>
<!--
    <b-row class="match-height">

      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>

    </b-row>
  -->
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton,BListGroup,
  BListGroupItem, } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,
    Keypress: () => import('vue-keypress'),
    BButton,
    BCard,
    EcommerceMedal,
    BListGroup,
    BListGroupItem,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      centerId: 0,
      stats: {},
      centerName: '',
      devices: {},
      last : {},
      key_press_enabled : true,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  watch: {
    last_person: {
      handler(newValue, oldValue) {
        if (newValue.user_type == 'MEMBER' ) {
          this.last[newValue.device] = newValue.code
        } else {
          this.last[newValue.device] = newValue.name
        }
        this.get_stats()
      },
    },
  },
  computed :{
    last_person() {
      return this.$store.getters['center/last_person']
    },
  },
  methods: {
    get_stats() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/center/`+this.$store.getters['center/currentCenterId']+`/dashboard/stats/`)
        .then(res => {
          this.stats = res.data
        })
    },
    key_press(r) {
        if (this.key_press_enabled === false) {
            return
        }
        if (r.event.keyCode == 123) {
            open_door
        }
    },
    get_device() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/center/${this.centerId}/device/`)
        .then(res => {
          this.devices = res.data
        })
    },
    open_door(sn) {
      this.$http.post(process.env.VUE_APP_AC_API_BASE+`/set_door/`+sn+`/on`)
        .then(res => {

        })
    },
  },
  created() {
    this.$http.defaults.headers.Token = this.userData.token
    this.centerId = this.$store.getters['center/currentCenterId']
    this.centerName = this.$store.getters['center/currentCenterName']
    this.get_device()
    this.get_stats()
//    console.log(process.env.VUE_APP_API_BASE)
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
